.dark .country-list {
    background-color: inherit;
}

.dark .selected-flag {
    background: inherit;
}

.dark .react-tel-input .form-control {
    background: inherit;
}

.dark .react-tel-input .flag-dropdown, .react-tel-input .flag-dropdown:hover {
    background-color: inherit;
}

.dark .react-tel-input .country-list .country .dial-code {
    color: inherit;
}

.dark .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: rgba(0,0,0,0.3);
}

.dark .react-tel-input .country-list {
    background-color: rgba(45,55,72,1);
}

.dark .react-tel-input .country-list .country:hover {
    background-color: rgb(55 65 82 / .8);
}

.dark .react-tel-input .country-list .country.highlight {
    background-color: rgb(55 65 82 / .8);
}

.dark .react-tel-input .flag-dropdown.open .selected-flag {
    background: rgba(0,0,0,0.3);
}

.dark .react-tel-input .flag-dropdown.open {
    background: rgba(0,0,0,0.3);
}

.phone-number-field-css-correction:focus {
    z-index: 1;
    border-color: #63b3ed;
    box-shadow: 0 0 0 1px #63b3ed;
}

.flag-dropdown-select-box-correction {
    height: 2.5rem;
    border-radius: 0.375rem;
    border-right: greenyellow;
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.04);
    background: rgba(255, 255, 255, 0.16);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.form-control:focus {
    z-index: 1;
    border-color: #63b3ed;
    box-shadow: 0 0 0 1px #63b3ed;
}

.react-tel-input .flag-dropdown {
    border-radius: 0.375rem 0 0 0.375rem;
    border: 0px solid;
    border-right-width: 1px;
    border-color: inherit;
}