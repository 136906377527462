.toolbar_container {
	display: flex;
	padding: 0.5rem;
	top: 0;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	justify-content: space-between;
}
.toolbar_item {
	font-size: 1rem;
	margin: 0.2rem;
	margin-top: 0rem;
	padding: 0.3rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-radius: 0.5rem;
	cursor: pointer;
}
.board_container {
	height: 80vh;
	display: flex;
}
.artboard_container {
	display: flex;
	height: 100%;
	flex-grow: 4;
	/* background-color: antiquewhite; */
}
.artboard {
	height: 100%;
	width: 80%;
	/* background-color: aqua; */
	margin: auto;
}
.propspane {
	height: 100%;
	flex-grow: 1;
	max-width: 20%;
	/* background-color: blueviolet; */
}
