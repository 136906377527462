.list-hover-slide {
    z-index: 1;
    transition: .4s ease color;
}

.is-current{
    filter: hue-rotate(90deg);
}

.list-hover-slide:before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    left: -150%;
    top: 0;
    width: 120%;
    height: 100%;
    border-right: solid 2px #DF4500;
    background: rgba(0, 0, 0, 0.2);
    transition: .4s ease left;
}
.list-hover-slide.is-current:before, .list-hover-slide:hover:before {
    left: -20%;
}

@media screen and (max-width: 48em) {
    .list-hover-slide:before {
        width: 100%;
        height: 100%;
        left: 0%;
        top: 150%;
        border-right: none;
        border-top: solid 2px #DF4500;
        transition: .4s ease top;
    }
    .list-hover-slide.is-current:before, .list-hover-slide:hover:before {
        top: 0%;
        left: -0%;
    }
}