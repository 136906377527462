@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Comfortaa:wght@300&family=Dancing+Script&family=Great+Vibes&family=Lato:wght@300&family=Lobster&family=Lora&family=Montserrat:wght@300&family=Noto+Sans&family=Open+Sans:wght@300&family=Oswald:wght@300&family=Pacifico&family=Poppins:wght@300&family=Roboto:wght@300&family=Rubik:wght@300&family=Sacramento&family=Staatliches&display=swap);

    .container {
        text-align: center;
    }
    
    .section-class {
        width: 100vw;
        display: inline-block;
        text-align: center;
        min-height: 100vh;
        vertical-align: top;
    }

@media only screen and (max-width: 600px) {
    .section-class {
        min-width: 300px;
    }
}
    
    .loader {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin: 75px;
        display: inline-block;
        vertical-align: middle;
    }
    
    /*LOADER-1*/
    
    .loader-1 .loader-outter {
        position: absolute;
        border: 4px solid #f50057;
        border-left-color: transparent;
        border-bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        -webkit-animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
        animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
    }
    
    .loader-1 .loader-inner {
        position: absolute;
        border: 4px solid #f50057;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        left: calc(50% - 20px);
        top: calc(50% - 20px);
        border-right: 0;
        border-top-color: transparent;
        -webkit-animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
        animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
    }
   
    /* ----------------     KEYFRAMES    ----------------- */
    
    @-webkit-keyframes loader-1-outter {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    
    @keyframes loader-1-outter {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    
    @-webkit-keyframes loader-1-inner {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
        }
    }
    
    @keyframes loader-1-inner {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
        }
    }
    
 
.builder_toolbar_container__zua18 {
	display: flex;
	padding: 0.5rem;
	top: 0;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	justify-content: space-between;
}
.builder_toolbar_item__3tBFO {
	font-size: 1rem;
	margin: 0.2rem;
	margin-top: 0rem;
	padding: 0.3rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-radius: 0.5rem;
	cursor: pointer;
}
.builder_board_container__1nzPX {
	height: 80vh;
	display: flex;
}
.builder_artboard_container__6-1Kc {
	display: flex;
	height: 100%;
	flex-grow: 4;
	/* background-color: antiquewhite; */
}
.builder_artboard__2qKNK {
	height: 100%;
	width: 80%;
	/* background-color: aqua; */
	margin: auto;
}
.builder_propspane__2TIsL {
	height: 100%;
	flex-grow: 1;
	max-width: 20%;
	/* background-color: blueviolet; */
}

/* font-family: 'Bebas Neue', cursive;
font-family: 'Comfortaa', cursive;
font-family: 'Dancing Script', cursive;
font-family: 'Great Vibes', cursive;
font-family: 'Lato', sans-serif;
font-family: 'Lobster', cursive;
font-family: 'Lora', serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Noto Sans', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Oswald', sans-serif;
font-family: 'Pacifico', cursive;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Rubik', sans-serif;
font-family: 'Sacramento', cursive;
font-family: 'Staatliches', cursive; */

.dark .country-list {
    background-color: inherit;
}

.dark .selected-flag {
    background: inherit;
}

.dark .react-tel-input .form-control {
    background: inherit;
}

.dark .react-tel-input .flag-dropdown, .react-tel-input .flag-dropdown:hover {
    background-color: inherit;
}

.dark .react-tel-input .country-list .country .dial-code {
    color: inherit;
}

.dark .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: rgba(0,0,0,0.3);
}

.dark .react-tel-input .country-list {
    background-color: rgba(45,55,72,1);
}

.dark .react-tel-input .country-list .country:hover {
    background-color: rgb(55 65 82 / .8);
}

.dark .react-tel-input .country-list .country.highlight {
    background-color: rgb(55 65 82 / .8);
}

.dark .react-tel-input .flag-dropdown.open .selected-flag {
    background: rgba(0,0,0,0.3);
}

.dark .react-tel-input .flag-dropdown.open {
    background: rgba(0,0,0,0.3);
}

.phone-number-field-css-correction:focus {
    z-index: 1;
    border-color: #63b3ed;
    box-shadow: 0 0 0 1px #63b3ed;
}

.flag-dropdown-select-box-correction {
    height: 2.5rem;
    border-radius: 0.375rem;
    border-right: greenyellow;
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.04);
    background: rgba(255, 255, 255, 0.16);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.form-control:focus {
    z-index: 1;
    border-color: #63b3ed;
    box-shadow: 0 0 0 1px #63b3ed;
}

.react-tel-input .flag-dropdown {
    border-radius: 0.375rem 0 0 0.375rem;
    border: 0px solid;
    border-right-width: 1px;
    border-color: inherit;
}
.tip_tap_editor_styles {
    min-height: 100px;
    overflow: auto;
    padding: 10px;
}

.tip_tap_header_styles {
    overflow: auto;
    border: 1px solid;
    padding: 10px;
}

.editor_link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.editor_link:visited {
    color: rgb(149, 57, 255);
}

.editor_link_button {
    display: inline-block; 
    padding: 16.0px 22px; 
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif; 
    font-size: 16.0px; 
    color: rgb(255,255,255); 
    text-decoration: none; 
    border-radius: 6.0px;
    background-color: #1a82e2;
}

.ProseMirror table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
}
.ProseMirror table td, .ProseMirror table th {
    border: 2px solid #ced4da;
    box-sizing: border-box;
    min-width: 1em;
    padding: 3px 5px;
    position: relative;
    vertical-align: top;
}
.ProseMirror table td > *, .ProseMirror table th > * {
    margin-bottom: 0;
}
.ProseMirror table th {
    background-color: #f1f3f5;
    font-weight: bold;
    text-align: left;
}
.ProseMirror table .selectedCell:after {
    background: rgba(200, 200, 255, 0.4);
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    z-index: 2;
}
.ProseMirror table .column-resize-handle {
    background-color: #adf;
    bottom: -2px;
    position: absolute;
    right: -2px;
    pointer-events: none;
    top: 0;
    width: 4px;
}
.ProseMirror table p {
    margin: 0;
}

.ProseMirror img.ProseMirror-selectednode {
    outline: 3px solid #68cef8;
}

.tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
}
.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
}

.tip_tap_editor_styles > h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > h4 {
    display: block;
    font-size: 1em;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > h5 {
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > h6 {
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

.tip_tap_editor_styles > ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

.tip_tap_editor_styles > hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
}
.list-hover-slide {
    z-index: 1;
    transition: .4s ease color;
}

.is-current{
    -webkit-filter: hue-rotate(90deg);
            filter: hue-rotate(90deg);
}

.list-hover-slide:before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    left: -150%;
    top: 0;
    width: 120%;
    height: 100%;
    border-right: solid 2px #DF4500;
    background: rgba(0, 0, 0, 0.2);
    transition: .4s ease left;
}
.list-hover-slide.is-current:before, .list-hover-slide:hover:before {
    left: -20%;
}

@media screen and (max-width: 48em) {
    .list-hover-slide:before {
        width: 100%;
        height: 100%;
        left: 0%;
        top: 150%;
        border-right: none;
        border-top: solid 2px #DF4500;
        transition: .4s ease top;
    }
    .list-hover-slide.is-current:before, .list-hover-slide:hover:before {
        top: 0%;
        left: -0%;
    }
}
