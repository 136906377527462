.tip_tap_editor_styles {
    min-height: 100px;
    overflow: auto;
    padding: 10px;
}

.tip_tap_header_styles {
    overflow: auto;
    border: 1px solid;
    padding: 10px;
}

.editor_link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.editor_link:visited {
    color: rgb(149, 57, 255);
}

.editor_link_button {
    display: inline-block; 
    padding: 16.0px 22px; 
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif; 
    font-size: 16.0px; 
    color: rgb(255,255,255); 
    text-decoration: none; 
    border-radius: 6.0px;
    background-color: #1a82e2;
}

.ProseMirror table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
}
.ProseMirror table td, .ProseMirror table th {
    border: 2px solid #ced4da;
    box-sizing: border-box;
    min-width: 1em;
    padding: 3px 5px;
    position: relative;
    vertical-align: top;
}
.ProseMirror table td > *, .ProseMirror table th > * {
    margin-bottom: 0;
}
.ProseMirror table th {
    background-color: #f1f3f5;
    font-weight: bold;
    text-align: left;
}
.ProseMirror table .selectedCell:after {
    background: rgba(200, 200, 255, 0.4);
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    z-index: 2;
}
.ProseMirror table .column-resize-handle {
    background-color: #adf;
    bottom: -2px;
    position: absolute;
    right: -2px;
    pointer-events: none;
    top: 0;
    width: 4px;
}
.ProseMirror table p {
    margin: 0;
}

.ProseMirror img.ProseMirror-selectednode {
    outline: 3px solid #68cef8;
}

.tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
}
.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
}

.tip_tap_editor_styles > h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > h4 {
    display: block;
    font-size: 1em;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > h5 {
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > h6 {
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.tip_tap_editor_styles > ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

.tip_tap_editor_styles > ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

.tip_tap_editor_styles > hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
}